import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>Ja pots preparar-te per l'accés a la Guàrdia Urbana de Barcelona.</p>

                <p>
                    El <strong>Curs online</strong> és un curs valorat per{" "}
                    <strong>5 estrelles</strong> entre els alumnes de l'anterior edició.
                </p>

                <p>
                    Al curs trobareu tot el <strong>temari actualitzat</strong> de la passada
                    convocatòria, classificats per assignatures.
                </p>

                <p>
                    <strong>Temari:</strong> vídeos explicatius de cada àmbit, resum del temari,
                    fitxes de treball, exercicis per posar-vos a prova i conceptes claus de cada
                    tema.
                </p>

                <p>
                    <strong>Cultura general:</strong> Fitxes explicatives i test per posar-vos a
                    prova.
                </p>

                <p>
                    <strong>Actualitat:</strong> Totes les notícies setmanals en una fitxa, test per
                    comprovar el vostre nivell i un videoresum de totes les notícies de cada mes.
                </p>

                <p>
                    <strong>Psicotècnics:</strong> Disposareu de vídeos explicatius i exercicis per
                    assolir el coneixement.
                </p>

                <p>
                    <strong>Premis, esports:</strong> Disposareu de material actualitzat per anar al
                    dia amb tots els premis, esports, esdeveniments, etc.
                </p>

                <p>
                    Aviat començaran les classes en directe on podreu interactuar amb la nostra
                    professora i aclarir dubtes.
                </p>

                <p>
                    Cada setmana s'aniran desbloquejant noves assignatures i en cap moment es
                    trauran, tot el material és vostre des del primer moment.
                </p>

                <p>Com ja veus, és el curs més complet sense dubte.</p>

                <p>
                    El curs estarà disponible fins al dia de l'examen i començarà a mesura que
                    s'apropi la següent convocatòria.
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/5yAiX9nms3E"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    ></iframe>
                </div>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't per l'accés a la Guàrdia Urbana de Barcelona amb el nostre curs online de 5 estrelles, amb temari actualitzat, classes en directe i tot el material que necessites per triomfar!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
